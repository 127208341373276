
export default {
  props: {
    placeholder: {
      type: String,
      default: 'Arama',
    },
    searchValue: {
      type: String,
      default: '',
    },
  },
  model: {
    prop: 'searchValue',
    event: 'changeValue',
  },
  methods: {
    updateValue(event) {
      this.$emit('changeValue', event.target.value)
    },
    onSubmit() {
      this.$emit('search')
    },
  },
}
