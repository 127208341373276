
export default {
  data() {
    return {
      isMobile: false,
      query: '',
      isOpen: false,
    }
  },
  mounted() {
    this.breakpoints()
    window.addEventListener('resize', this.breakpoints, {
      passive: true,
    })
    document.addEventListener('click', this.documentClick)
  },
  beforeDestroy() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.breakpoints, {
        passive: true,
      })
    }
    document.removeEventListener('click', this.documentClick)
  },
  methods: {
    breakpoints() {
      this.isMobile = window.innerWidth < 992
    },
    searchToggle() {
      this.isOpen = !this.isOpen
    },
    documentClick(e) {
      if (!this.$el.contains(e.target)) {
        this.isOpen = false
      }
    },

    search() {
      this.$router.push({ path: '/search/', query: { q: this.query } })
      this.query = ''
      this.isOpen = false
    },
  },
}
