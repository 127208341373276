
export default {
  props: {
    type: {
      type: String,
      default: 'text',
    },
    name: {
      type: String,
      default: 'name',
    },
    placeholder: {
      type: String,
      default: 'Placeholder',
    },
   value: {
      type: String,
      default: '',
    },
  },
  methods: {
    handleInput(event) {
      this.$emit('input', event.target.value)
    },
  },
}
