
export default {
  name: 'MenuItem',
  props: {
    title: {
      type: String,
      default: '#',
    },
    description: {
      type: String,
      default: '#',
    },
    url: {
      type: String,
      default: '#',
    },
    external: {
      type: Boolean,
      default: false,
    },
  },
}
