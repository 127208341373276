import { render, staticRenderFns } from "./NavigationMobile.vue?vue&type=template&id=4a3a984c"
import script from "./NavigationMobile.vue?vue&type=script&lang=js"
export * from "./NavigationMobile.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ButtonLink: require('/srv/app/components/button/Link.vue').default,Icon: require('/srv/app/components/icon/Icon.vue').default,NavigationMenuItem: require('/srv/app/components/navigation/MenuItem.vue').default,Button: require('/srv/app/components/button/Button.vue').default,Search: require('/srv/app/components/search/Search.vue').default})
