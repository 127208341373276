
export default {
  props: {
    label: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: 'green',
    },
    size: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: null,
    },
    url: {
      type: String,
      default: '',
    },
    buttonId: {
      type: String,
    },
    buttonClass: {
      type: String,
    },
    external: {
      type: Boolean,
      default: false,
    },
    enabled: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    hasIcon() {
      return this.icon && this.icon !== 'none' && this.icon !== null
    },

    isRightIcon() {
      const iconNames = [
        'angle_right',
        'chevron_right',
        'arrow_alt_to_botom',
        'external_link',
        'long_arrow_alt_right',
      ]
      return iconNames.includes(this.icon) > -1
    },

    className() {
      return this.hasIcon
        ? 'button-link button-link-icon button-link-' + this.color
        : 'button-link'
    },
  },
  methods: {
    onClick() {
      this.$emit('click')
    },
  },
}
