
import TopMenu from '@/topMenu.json'
import TopRightMenu from '@/topRightMenu.json'

export default {
  name: 'MainMenu',

  props: {
    status: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      menu: [...TopMenu],
      subLink: [],
      activeMenu: [],
      concatMenu: [...TopMenu, ...TopRightMenu],
      menuFooter: {
        active: true,
        phone: '0 (850) 225 10 10',
      },
    }
  },
  computed: {
    getMenu() {
      return this.activeMenu.length === 0
        ? this.concatMenu
        : this.activeMenu[this.activeMenu.length - 1].subMenu
    },

    itemHasSubMenu() {
      return this.activeMenu.length > 0 && this.getMenu[0].subMenu
    },

    menuType() {
      return this.activeMenu.length > 0 &&
        this.activeMenu[this.activeMenu.length - 1].menuType
        ? this.activeMenu[this.activeMenu.length - 1].menuType
        : ''
    },
  },
  watch: {
    status() {
      this.activeMenu = []
      this.subLink = []
    },
  },

  methods: {
    setActiveMenu(menu) {
      this.activeMenu.push(menu)
      if (menu.subLink) {
        this.subLink = menu.subLink
      } else {
        this.subLink = []
      }
    },
    goBack() {
      this.activeMenu.splice(-1)
      this.subLink =
        this.activeMenu.length > 0
          ? this.activeMenu[this.activeMenu.length - 1].subLink
          : []
    },
    initUrl(url, isExternal) {
      if (!url || url.length === '') return ''

      if (isExternal) {
        return url
      }

      return url[0] === '/' ? url : `/${url}`
    },
  },
}
